'use client'

import type { ReactNode } from 'react'

import TEST_IDS from '../../constants/testIds'
import type { CreateComponentsConfig } from '../../types'

import styles from '../GenericStyles.module.scss'

interface ClientButtonProps {
	readonly children: ReactNode
	readonly customProps?: CreateComponentsConfig['buttonProps']
}

export function ClientButton({ children, customProps }: ClientButtonProps) {
	const extraProps = { className: styles.button, ...customProps }

	return (
		<button data-testid={TEST_IDS.BUTTON} type='button' {...extraProps}>
			{children}
		</button>
	)
}
